// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-guide-post-js": () => import("./../src/templates/guide-post.js" /* webpackChunkName: "component---src-templates-guide-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anki-jsx": () => import("./../src/pages/anki.jsx" /* webpackChunkName: "component---src-pages-anki-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-languages-jsx": () => import("./../src/pages/languages.jsx" /* webpackChunkName: "component---src-pages-languages-jsx" */),
  "component---src-pages-mnemonics-for-chinese-characters-book-jsx": () => import("./../src/pages/mnemonics-for-chinese-characters-book.jsx" /* webpackChunkName: "component---src-pages-mnemonics-for-chinese-characters-book-jsx" */),
  "component---src-pages-progress-jsx": () => import("./../src/pages/progress.jsx" /* webpackChunkName: "component---src-pages-progress-jsx" */),
  "component---src-pages-subscribe-js": () => import("./../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-tools-js": () => import("./../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

